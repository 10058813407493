import { i18n } from "@lingui/core";
import * as englishMessages from "./locales/en/messages.mjs";
import { setupMissingTranslationsReporting } from "@ingka-livlig/frontend-lib";

//This needs to be copied for all projects, as the dynamic import feature is dependent on the file's location.
export async function localeActivate(locale: string) {
    try {
        const { messages } = await import(`./locales/${locale}/messages.mjs`);
        i18n.loadAndActivate({ locale, messages });
        //Also update html document to inform the browser about the language used.
        document.getElementsByTagName("html").item(0)?.setAttribute("lang", locale);
    } catch (e) {
        console.error(
            `There was an error when activating the locale '${locale}'. Will continue with default locale.`,
            e,
        );
        i18n.loadAndActivate({ locale: "en", messages: englishMessages.messages });
    }
}

export function activateDefaultLocale() {
    i18n.loadAndActivate({ locale: "en", messages: englishMessages.messages });
}

setupMissingTranslationsReporting(englishMessages);
